<nb-card>
    <nb-card-header>
        <ng-content select="[header]"></ng-content>
        <ng-content select="[button]"> </ng-content>
      </nb-card-header>
    
      <nb-card-body>
        <ng-content></ng-content>
      </nb-card-body>
  </nb-card>
