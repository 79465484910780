<div class="container">
  <div class="row">
    <div class="col-md-3">
      <div class="card-counter primary">
        <i class="fa fa-tasks"></i>
        <span class="count-numbers">{{ projectCount }}</span>
        <span class="count-name">Projects</span>
      </div>
    </div>

    <div class="col-md-3" *ngIf="isAdmin">
      <div class="card-counter danger">
        <i class="fa fa-users"></i>
        <span class="count-numbers">{{ userCount }}</span>
        <span class="count-name">Users</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card-counter success">
        <i class="fa fa-database"></i>
        <span class="count-numbers">{{ recordsCount }}</span>
        <span class="count-name">Records</span>
      </div>
    </div>

    <div class="col-md-3" *ngIf="isAdmin">
      <div class="card-counter info">
        <i class="fa fa-user"></i>
        <span class="count-numbers">{{ associateCount }}</span>
        <span class="count-name">Associates</span>
      </div>
    </div>
  </div>
</div>


<!-- <div class="row">
  <div class="col-xxxl-3 col-xxl-4 col-lg-5 col-md-6">
    <ngx-temperature></ngx-temperature>
  </div>

  <div class="col-xxxl-9 col-xxl-8 col-lg-7 col-md-6">
    <ngx-electricity></ngx-electricity>
  </div>
</div>

<div class="row">
  <div class="col-xxxl-9 col-xl-12">
    <ngx-rooms></ngx-rooms>
  </div>

  <div class="col-xxxl-3 col-xxl-4 col-lg-7 col-md-6">
    <ngx-contacts></ngx-contacts>
  </div>

  <div class="col-xxxl-3 col-xxl-4 col-lg-5 col-md-6">
    <ngx-solar [chartValue]="solarValue"></ngx-solar>

    <ngx-kitten></ngx-kitten>
  </div>

  <div class="col-xxxl-3 col-xxl-4 col-md-5">
    <ngx-traffic></ngx-traffic>
    <ngx-weather></ngx-weather>
  </div>

  <div class="col-xxxl-6 col-xxl-12 col-md-7">
    <ngx-security-cameras></ngx-security-cameras>
  </div>
</div> -->