import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.scss']
})
export class SmartTableComponent implements OnInit {

  @Output() custom = new EventEmitter();
  @Output() editConfirm = new EventEmitter();
  @Output() deleteConfirm = new EventEmitter();
  @Input() source: [];
  @Input() columns;
  @Input() actions;
  settings;

  constructor() { }

  ngOnInit(): void {
    this.settings = {
      actions : this.actions,
      columns : this.columns,
    }
  }

  onCustomAction(event){
    this.custom.emit(event);
  }

  onDeleteConfirm(event){
    this.deleteConfirm.emit(event);
  }

  onEdit(event){
    this.editConfirm.emit(event);
  }

}
