import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiURL + 'dashboard';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private router: Router, private toasterService: NbToastrService) {
  }
  
  getDashboardCounts() {
    return this.http.get(`${BACKEND_URL}/dashboardCounts`);
  }
}
