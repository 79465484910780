<nb-card size="medium">
  <nb-card-body>
    <div class="progress-info" *ngFor="let item of progressInfoData">
      <div class="subtitle">{{ item.title }}</div>
      <div class="h3">{{ item.value | ngxNumberWithCommas }}</div>
      <nb-progress-bar [value]="item.activeProgress"></nb-progress-bar>
      <div class="caption description">
        <bdi>{{ item.description }}</bdi>
      </div>
    </div>
  </nb-card-body>
</nb-card>
