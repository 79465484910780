import { Component } from '@angular/core';

interface CardSettings {
  title: string;
  iconClass: string;
  type: string;
}
@Component({
  selector: 'ngx-ecommerce',
  templateUrl: './e-commerce.component.html',
})
export class ECommerceComponent {
  solarValue: number;
  lightCard: CardSettings = {
    title: 'Light',
    iconClass: 'nb-lightbulb',
    type: 'primary',
  };
  rollerShadesCard: CardSettings = {
    title: 'Roller Shades',
    iconClass: 'nb-roller-shades',
    type: 'success',
  };
  wirelessAudioCard: CardSettings = {
    title: 'Wireless Audio',
    iconClass: 'nb-audio',
    type: 'info',
  };
  coffeeMakerCard: CardSettings = {
    title: 'Coffee Maker',
    iconClass: 'nb-coffee-maker',
    type: 'warning',
  };

  statusCards: CardSettings[] = [
    {
      title: 'Light',
      iconClass: 'nb-lightbulb',
      type: 'primary',
    },
    {
      title: 'Roller Shades',
      iconClass: 'nb-roller-shades',
      type: 'success',
    },
    {
      title: 'Wireless Audio',
      iconClass: 'nb-audio',
      type: 'info',
    },
    {
      title: 'Coffee Maker',
      iconClass: 'nb-coffee-maker',
      type: 'warning',
    },
  ];
}
