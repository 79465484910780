import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { SharedService } from '../../services/shared.service';
import { USER_ROLES } from '../../utils/constants';

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnDestroy, OnInit {

  projectCount = 0;
  userCount = 0;
  recordsCount = 0;
  associateCount = 0;
  isAdmin = false;
  constructor(
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private route: Router,
  ) {
    if (localStorage.getItem('loginData')) {
      let data = JSON.parse(localStorage.getItem('loginData'));
      let role_id = data?.role_id;
      if (role_id == USER_ROLES.ASSOCIATE_ROLE_ID) {
        if (localStorage.getItem('startDayData')) {
          route.navigate(['/user/feedbacks/create'])
        }
        else
          route.navigate(['/user/attendance'])
      }
      if (role_id == USER_ROLES.ADMIN_ROLE_ID || role_id == USER_ROLES.SUPERADMIN_ROLE_ID) {
        this.isAdmin = true;
      }
    }
    this.loadCounts();
  }

  ngOnInit(): void {
    this.sharedService.refreshMenus();
  }

  loadCounts() {
    this.dashboardService.getDashboardCounts().subscribe(
      (res: any) => {
        console.log(res);
        this.projectCount = res.data.projectCount;
        this.userCount = res.data.userCount;
        this.recordsCount = res.data.recordsCount;
        this.associateCount = res.data.associateCount;
      },
      err => {
        console.log(err);

      }
    )
  }

  ngOnDestroy() {
  }
}
