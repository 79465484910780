<nb-card-header>
  Earnings
</nb-card-header>
<nb-card-body>
  <div class="chart-info">
    <div [style.color]="color">{{ name }}</div>
    <div class="time-period caption">Last week:</div>
    <div class="h6">{{ value }}%</div>
  </div>
  <ngx-earning-pie-chart [values]="earningPieChartData"
                         (selectPie)="changeChartInfo($event)"
                         [defaultSelectedCurrency]="defaultSelectedCurrency">
  </ngx-earning-pie-chart>
</nb-card-body>
