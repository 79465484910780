import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../environments/environment';
import { API } from '../../utility/api-list';
import { User, userVerify, verifyOTP } from '../auth/auth-data.model';
import { USER_ROLES } from '../utils/constants';

const BACKEND_URL = environment.apiURL + 'auth';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private isAuthenticated = false;
  messageSuccess: boolean;
  alerts: any;

  constructor(private http: HttpClient, private router: Router, private toasterService: NbToastrService) {
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  isLoggedIn() {
    let LoginDetails: any = localStorage.getItem("isUserLoggedIn");
    if (LoginDetails) {
      console.log("User is already logged in!!");
      this.isAuthenticated = true;
      return true;
    }
    else {
      return false;
    }
  }

  ValidateLogin(user) {
    return this.http.post(BACKEND_URL, user)
  }

  ValidateLoginAssociate(user) {
    return this.http.post(`${BACKEND_URL}/associate`, user);
  }

  startDay(data, photo) {
    let formData: FormData = new FormData();
    formData.append('login_selfie', photo, photo?.name);
    this.buildFormData(formData, data, false);
    return this.http.post(`${BACKEND_URL}/startday`, formData)
  }
  
  endDay(data, photo) {
    let formData: FormData = new FormData();
    formData.append('logout_selfie', photo, photo?.name);
    this.buildFormData(formData, data, false);
    return this.http.post(`${BACKEND_URL}/endday`, formData)
  }

  changePassword(userdata) {
    let data = JSON.parse(localStorage.getItem('loginData'));
    let email = data?.email;
    return this.http.put(BACKEND_URL, { email: email, password: userdata.oldPassword, newPassword: userdata.newPassword })
  }

  forgotPassword(userDetails: userVerify) {
    return this.http.post(`${BACKEND_URL}/forgotPassword`, userDetails);
  }

  verifyOTP(otpverify: verifyOTP) {
    return this.http.post(`${BACKEND_URL}/verifyOTP`, otpverify);
  }

  resetPassword(req) {
    return this.http.put<any>(`${BACKEND_URL}/resetPassword`, req);
  }

  logout() {
    let navigateUrl = "/";
    let data = JSON.parse(localStorage.getItem('loginData'));
    let role_id = data?.role_id;
    console.log(role_id);
    
    if (role_id == USER_ROLES.ASSOCIATE_ROLE_ID) navigateUrl = "associate-login";
    localStorage.removeItem('isUserLoggedIn');
    localStorage.removeItem('loginData');
    localStorage.removeItem('projectsData');
    this.router.navigateByUrl(navigateUrl);
    console.log("Logged out");
  }
  
  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }
}
