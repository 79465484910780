<nb-card size="medium">
  <nb-card-header>
    <h3>Visitors Analytics</h3>
    <div class="subtitle">Consumption</div>
  </nb-card-header>
  <nb-card-body>
    <div class="chart-container">
      <div class="chart-header">
        <ngx-legend-chart [legendItems]="chartLegend"></ngx-legend-chart>
      </div>
      <ngx-visitors-analytics-chart [chartData]="visitorsAnalyticsData"></ngx-visitors-analytics-chart>
    </div>
  </nb-card-body>

  <ngx-slide-out [showVisitorsStatistics]="true">
    <ngx-visitors-statistics [value]="pieChartValue"></ngx-visitors-statistics>
  </ngx-slide-out>
</nb-card>
