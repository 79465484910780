import { EventEmitter, Injectable } from '@angular/core';
import { ADMIN_MENU_ITEMS, MENU_ITEMS } from '../pages/pages-menu';
import { USER_ROLES } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  onMenuChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  getMenu() {
    if (localStorage.getItem('loginData')) {
      let data = JSON.parse(localStorage.getItem('loginData'));
      let projectsData = JSON.parse(localStorage.getItem('projectsData'));
      console.log(projectsData);

      if (data.routes) {
        let routes = data.routes.split(",");

        if (routes.length) {

          if (routes[0] === "All") {
            return ADMIN_MENU_ITEMS;
          } else {
            let menu = []
            for (let m of MENU_ITEMS) {
              for (let n of routes) {
                if (n === m.title) {

                  // NO Dashboard for Associate
                  if (data?.role_id == USER_ROLES.ASSOCIATE_ROLE_ID && n == "Dashboard") {
                    m = {
                      title: 'Attendance',
                      icon: 'home-outline',
                      link: '/user/attendance'
                    };
                  }

                  // if (m.title == "Task List") {
                  //   if (projectsData?.length) {
                  //     m.children = m.children.filter(x => projectsData.some(y => y.projectType == x.title));
                  //   } else {
                  //     m.children = [];
                  //   }
                  // }

                  if (m.title == "Smart List") {
                    if (projectsData?.length) {
                      m.children = m.children.filter(x => projectsData.some(y => y.projectType == x.title));
                    } else {
                      m.children = [];
                    }
                  }

                  menu.push(m);
                }
              }
            }
            return menu;
          }

        }
      }
    }
  }

  refreshMenus() {
    this.onMenuChange.emit(this.getMenu())
  }

}
