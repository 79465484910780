<nb-card-header>
  <i class="ion-social-usd"></i>
  <span class="title">Profit</span>
</nb-card-header>
<nb-card-body>
  <div class="info">
    <div class="period">
      <div class="caption">Jun 1 - Jun 30</div>
      <div class="label">
        <span class="currency">$</span>300
      </div>
    </div>
    <div class="period latest">
      <div class="caption">Jul 1 - Jul 31</div>
      <div class="label">
        <span class="currency">$</span>860
      </div>
    </div>
  </div>
  <ngx-stats-ares-chart [points]="chartData"></ngx-stats-ares-chart>
</nb-card-body>
