import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbTabsetModule, NbDatepickerModule, NbButtonModule, NbCardModule, NbInputModule, NbChatModule, NbDialogModule, NbSidebarModule, NbToastrModule, NbWindowModule, NbMenuModule, NbUserModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ThemeModule } from '../../@theme/theme.module';
import { DashboardModule } from '../../pages/dashboard/dashboard.module';
import { ECommerceModule } from '../../pages/e-commerce/e-commerce.module';
import { SmartTableComponent } from '../smart-table/smart-table.component';
import { CardComponent } from '../card/card.component';



@NgModule({
  declarations: [
    SmartTableComponent,
    CardComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    // UiSwitchModule,
    ThemeModule,
    NbTabsetModule,
    FormsModule,
    NbDatepickerModule,
    ReactiveFormsModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbChatModule,
    NbDialogModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
    Ng2SmartTableModule,
    NbMenuModule,
    NbUserModule,
    NbSelectModule,
    DashboardModule,
    ECommerceModule,
    NbSpinnerModule
  ],

  exports: [
    CommonModule,
    HttpClientModule,
    // UiSwitchModule,
    ThemeModule,
    NbTabsetModule,
    FormsModule,
    NbDatepickerModule,
    ReactiveFormsModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbChatModule,
    NbDialogModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
    Ng2SmartTableModule,
    NbMenuModule,
    NbUserModule,
    NbSelectModule,
    SmartTableComponent,
    CardComponent,
    DashboardModule,
    ECommerceModule,
    NbSpinnerModule
  ]
})
export class CommonImportsModule { }
